let api_link;
let home_link;

if (process.env.ENV === "production") {
  console.log("Using production links");
  api_link = "https://api.sayhomee.com";
  home_link = "https://www.sayhomee.com";
} else if (process.env.ENV === "staging") {
  console.log("Using staging links");
  api_link = "https://api.staging.sayhomee.com";
  home_link = "https://staging.sayhomee.com";
  // } else if (process.env.ENV === 'staging') {
  //   console.log("Using staging links")
  //   api_link = "https://sayhomee-server-staging-17bdeee9e3cf.herokuapp.com"
  //   home_link = "https://sayhomee-web-staging-3100fccb29fc.herokuapp.com"
} else {
  console.log("Using dev linksssss");
  // api_link = "http://localhost:8000"
  home_link = "http://localhost:3000";
  // api_link = "http://192.168.50.43:8000"
  // api_link = "http://10.10.3.201:8000"
  // api_link = "http://192.168.18.102:62000";
  // api_link = "http://192.168.100.90:62000";
  api_link = "https://api.staging.sayhomee.com";
  // api_link = "http://192.168.0.109:8000";
}

export { api_link, home_link };
