import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import Lightbox from "react-images-with-video";
import {
  ADUImageSelection,
  ADUChatMap,
  ADUImageCrop,
  ADUBedBathSelection,
} from "./aduinputcomps";
import { Button as AntdButton, Tooltip } from "antd";
import { handleConversation } from "./aduchatutils";
import { InlineWidget } from "react-calendly";
import { EyeOutlined } from "@ant-design/icons";
import aduCahtBotIcon from "../assets/icons/adu-chatbot-icon.svg";
import attachedAduImage from "../assets/images/attached-adu-image.svg";
import dettachedAduImage from "../assets/images/dettached-adu-image.svg";

export const ADUModalBody = ({
  typing,
  setTyping,
  messages = [],
  setMessages,
  sessionId,
  chatWindowRef = null,
  modalBodyHeight = 0,
  selectedLocation,
  setSelectedLocation,
  viewport,
  setViewport,
  mapCompInput,
  setMapCompInput,
  aduImages,
  setAduImages,
  selectedImage,
  setSelectedImage,
  setCompletedCrop,
  imageForCrop,
  area,
  setArea,
  setImageForCrop,
  bedrooms,
  setBedrooms,
  bathrooms,
  setBathrooms,
  bedroomOptions,
  bathroomOptions,
}) => {
  const usedKeys = new Set();
  let lastMessage = messages[messages?.length - 1];

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatWindowRef.current) {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
    };

    // Initial scroll
    scrollToBottom();

    setTimeout(() => scrollToBottom(), 100);
  }, [messages, chatWindowRef]);

  const handleModalClick = () => {
    if (selectedImage) {
      setSelectedImage(null);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
      onClick={handleModalClick} // Add this line
    >
      <div
        className="custom-scrollbar"
        style={{
          flexGrow: 1,
          overflowY: "auto",
          overflowX: "hidden",
          padding: 20,
          paddingBottom: "90px",
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
        ref={chatWindowRef}
      >
        {messages?.map((message, index) => {
          let randomKey;
          // Generate a random key until it's unique
          do {
            randomKey = Math.floor(Math.random() * 1000000);
          } while (usedKeys.has(randomKey));

          // Add the key to the set of used keys
          usedKeys.add(randomKey);
          if (message?.content || message?.showImgSlctnMsg) {
            return (
              <ADUMessageCard
                typing={typing}
                setTyping={setTyping}
                key={randomKey}
                messages={messages}
                setMessages={setMessages}
                sessionId={sessionId}
                message={message}
                index={index}
                length={messages?.length}
                setAduImages={setAduImages}
                setImageForCrop={setImageForCrop}
                bedrooms={bedrooms}
                bathrooms={bathrooms}
                bedroomOptions={bedroomOptions}
                bathroomOptions={bathroomOptions}
              />
            );
          }
        })}

        {lastMessage?.showAddress && (
          // <ADUMessageCardWrapper sender="system">
          <ADUChatMap
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            viewport={viewport}
            setViewport={setViewport}
            mapCompInput={mapCompInput}
            setMapCompInput={setMapCompInput}
          />
          // </ADUMessageCardWrapper>
        )}

        {lastMessage?.showBedBathSelection && (
          <ADUBedBathSelection
            bedrooms={bedrooms}
            setBedrooms={setBedrooms}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
            bedroomOptions={bedroomOptions}
            bathroomOptions={bathroomOptions}
          />
        )}

        {lastMessage?.showImage && (
          <ADUImageSelection
            aduImages={aduImages}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            sessionId={sessionId}
            setAduImages={setAduImages}
            bedrooms={bedrooms}
            bathrooms={bathrooms}
          />
        )}

        {lastMessage?.showCropImage && (
          <ADUImageCrop
            sessionId={sessionId}
            imageUrl={imageForCrop}
            setCompletedCrop={setCompletedCrop}
            area={area}
            setArea={setArea}
          />
        )}
      </div>
    </div>
  );
};

export const ADUMessageCard = ({
  typing,
  setTyping,
  message,
  setMessages,
  sessionId,
  index,
  length,
  setAduImages,
  setImageForCrop,
  bedrooms,
  bathrooms,
  bedroomOptions,
  bathroomOptions,
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const lightboxphotos = [
    {
      src: message.isImage ? message.content : "",
      type: null,
    },
  ];
  const [LightboxState, setLightboxState] = useState({
    lightBoxOpen: false,
    currentIndex: 0,
    count: lightboxphotos.length,
    photos: lightboxphotos,
    lightBoxPhotos: lightboxphotos,
    isLoading: false,
    hasMore: true,
  });

  const onClickPhoto = (index) => {
    setLightboxState((prev) => ({
      ...prev,
      lightBoxOpen: true,
      currentIndex: 0,
    }));
    setImageIndex(index);
  };

  const onClickPrevious = () => {
    setLightboxState((prev) => ({
      ...prev,
      currentIndex: prev.currentIndex - 1,
    }));
  };

  const onClickNext = () => {
    setLightboxState((prev) => ({
      ...prev,
      currentIndex: prev.currentIndex + 1,
    }));
  };

  const handleClickButton = (value) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      if (newMessages.length > 0) {
        // Edit the last message
        newMessages[newMessages.length - 1] = {
          ...newMessages[newMessages.length - 1],
          showButtons: false,
          btnContent: null,
        };
      }
      return newMessages;
    });

    handleConversation({
      setTyping,
      value,
      sessionId,
      setMessages,
      setAduImages,
      setImageForCrop,
      bedrooms,
      bathrooms,
    });
  };

  const handleClickAttachDetach = (value, btnContent) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      if (newMessages.length > 0) {
        // Edit the last message
        newMessages[newMessages.length - 1] = {
          ...newMessages[newMessages.length - 1],
          showButtons: false,
          btnContent: btnContent,
          isAttachDetach: true,
          content: value,
        };
      }
      return newMessages;
    });

    handleConversation({
      setTyping,
      value,
      sessionId,
      setMessages,
      setAduImages,
      setImageForCrop,
      bedrooms,
      bathrooms,
    });
  };

  return (
    <ADUMessageCardWrapper sender={message?.sender} message={message}>
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          key={index}
          style={{
            width: "100%",
            display: "flex",
            flexDirection:
              message?.showButtons || message?.showCalendly ? "column" : "row",
            justifyContent: message?.sender === "user" ? "end" : "start",
          }}
        >
          <div
            className={`${index === length - 1 ? "adu-fadein-animation" : ""}
          
          `}
            style={{
              maxWidth: message?.isAttachDetach ? "100%" : 490,
              padding: message?.sender === "user" ? 10 : "",
              color: "#9F4300",
              backgroundColor: message?.showImgSlctnMsg
                ? "transparent"
                : message?.sender === "user"
                ? "#FFFFFF"
                : "transparent",
              border: message?.sender === "user" ? "1px solid #F5E5DB" : "none",
              borderRadius: message?.sender
                ? "20px 20px 0px 20px"
                : "20px 20px 20px 0px",
              // color: message?.sender === "user" ? "black" : "white",
            }}
          >
            {message?.showImgSlctnMsg ? (
              <ADUMessageImageSlctn
                selectedImage={message.selectedImage}
                aduImages={message.aduImages}
              />
            ) : message?.isBedBath ? (
              <ADUIsBedBathComp
                value={message?.content}
                bedroomOptions={bedroomOptions}
                bathroomOptions={bathroomOptions}
              />
            ) : message?.isAttachDetach ? (
              <ADUIsAttachDetach
                content={message?.content}
                btnContent={message?.btnContent}
              />
            ) : message?.isImage ? (
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  onClickPhoto(index);
                }}
                src={message?.content}
                alt="adu"
                style={{
                  objectFit: "cover",
                  aspectRatio: "square",
                  minHeight: "250px",
                  minWidth: "250px",
                  maxHeight: "350px",
                  maxWidth: "350px",
                  height: "100%",
                  width: "100%",
                  borderRadius:
                    message?.sender === "user"
                      ? "9px 9px 0px 9px"
                      : "9px 9px 9px 0px",
                }}
              />
            ) : typeof message?.content === "string" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(message?.content),
                }}
                className="bg-white w-fit"
              />
            ) : (
              <div className="bg-white w-fit">{message?.content}</div>
            )}
          </div>

          {message?.showButtons && (
            <div
              className={index === length - 1 ? "adu-fadein-animation" : ""}
              style={{
                display: "flex",
                // flexWrap: "wrap",
                gap: 10,
                marginTop: "10px",
              }}
            >
              {message?.btnContent?.map((value, index) => {
                const isDetached = value.toLowerCase().includes("detached");
                const isAttached = value.toLowerCase().includes("attached");
                return isAttached || isDetached ? (
                  <AntdButton
                    key={index}
                    style={{
                      // backgroundColor:
                      //   message?.sender === "user" ? "#E3E3E3" : "#F5A724",
                      borderRadius: 20,
                      // color: "white",
                      padding: "12px 0 0 0",
                      height: "unset",
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                      width: "100%",
                      maxWidth: 452,
                      maxHeight: 452,
                      overflow: "hidden",
                    }}
                    onClick={() =>
                      handleClickAttachDetach(value, message?.btnContent)
                    }
                  >
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        padding: "5px 20px",
                      }}
                    >
                      {value}
                    </div>
                    <div
                      style={{
                        fontSize: 14,
                        padding: "5px 20px",
                        whiteSpace: "normal",
                        overflowWrap: "break-word",
                        textAlign: "left",
                      }}
                    >
                      {isDetached
                        ? "A separate dwelling unit not attached to the main house, providing privacy and independence."
                        : "A unit that shares a wall with the main house, offering easy access and shared utilities."}
                    </div>
                    <img
                      src={isAttached ? attachedAduImage : dettachedAduImage}
                      alt="adu"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </AntdButton>
                ) : (
                  <AntdButton
                    key={index}
                    className="btn-gradient-border"
                    style={{
                      // backgroundColor:
                      //   message?.sender === "user" ? "#E3E3E3" : "#F5A724",
                      borderRadius: 20,
                      // color: "white",
                      padding: "4px 12px",
                      height: "unset",
                    }}
                    onClick={() => handleClickButton(value)}
                  >
                    {value}
                  </AntdButton>
                );
              })}
            </div>
          )}

          {/* <ADUShowCalendly /> */}

          {/* <CalendlyPopup message={message} index={index} length={length} /> */}
          {message?.showCalendly && (
            <a
              href={message?.calendlyLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <AntdButton
                key={index}
                type="primary"
                style={{
                  borderRadius: "12px",
                  color: "white",
                  padding: "2px 12px",
                  height: "unset",
                  marginTop: "10px",
                }}
              >
                Schedule a Meeting
              </AntdButton>
            </a>
          )}

          {LightboxState.lightBoxOpen &&
            imageIndex === index &&
            message.isImage && (
              <Lightbox
                images={LightboxState.lightBoxPhotos}
                isOpen={LightboxState.lightBoxOpen}
                currentImage={LightboxState.currentIndex}
                onClickPrev={onClickPrevious}
                onClickNext={onClickNext}
                onClose={(e) =>
                  setLightboxState({ ...LightboxState, lightBoxOpen: false })
                }
              />
            )}
        </div>

        {typing && index === length - 1 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              marginTop: 20,
            }}
            className=""
          >
            <div
              className="adu-fadein-animation"
              style={{
                maxWidth: 350,
                padding: 10,
                backgroundColor: "white",
                borderRadius: "20px 20px 20px 0px",
                color: "white",
                border: "1px solid #F5E5DB",
              }}
            >
              <div className="aduchat-typing-loader" />
            </div>
          </div>
        )}
      </div>
    </ADUMessageCardWrapper>
  );
};

// export const ADUMessageImageSlctn = ({ aduImages, selectedImage }) => {
//   const lightboxphotos = aduImages.map((values) => {
//     return {
//       src: values.url,
//       type: null,
//     };
//   });
//   const [state, setState] = useState({
//     lightBoxOpen: false,
//     currentIndex: 0,
//     count: lightboxphotos.length,
//     photos: lightboxphotos,
//     lightBoxPhotos: lightboxphotos,
//     isLoading: false,
//     hasMore: true,
//   });

//   const onClickPhoto = (index) => {
//     setState((prev) => ({
//       ...prev,
//       lightBoxOpen: true,
//       currentIndex: index,
//     }));
//   };

//   const onClickPrevious = () => {
//     setState((prev) => ({
//       ...prev,
//       currentIndex: prev.currentIndex - 1,
//     }));
//   };

//   const onClickNext = () => {
//     setState((prev) => ({
//       ...prev,
//       currentIndex: prev.currentIndex + 1,
//     }));
//   };

//   return (
//     <div className="adu-fadein-animation" style={{ color: "black" }}>
//       <div
//         style={{
//           fontSize: "18px",
//           fontWeight: "bold",
//           width: "100%",
//           maxWidth: "350px",
//         }}
//       >
//         Selected image
//       </div>

//       <div
//         style={{
//           position: "relative",
//           display: "grid",
//           gap: 5,
//           gridTemplateColumns: "repeat(2, 1fr)",
//           gridTemplateRows: "repeat(2, auto)",
//           width: "100%",
//           maxWidth: "350px",
//           maxHeight: "350px",
//         }}
//       >
//         {(aduImages || [])?.map((image, index) => (
//           <div
//             key={image.id}
//             style={{
//               position: "relative",
//               border:
//                 image?.id === selectedImage?.id
//                   ? "2px solid rgb(245, 167, 36)"
//                   : "2px solid lightgray",
//               borderRadius: 8,
//               padding: 2,
//               opacity: image?.id === selectedImage?.id ? 0.7 : 1,
//             }}
//           >
//             <img
//               onClick={(e) => {
//                 e.stopPropagation();
//               }}
//               src={image.url}
//               alt={`ADU ${image.id}`}
//               style={{
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover",
//                 borderRadius: 8,
//               }}
//             />
//             <div
//               onClick={() => onClickPhoto(index)}
//               style={{
//                 position: "absolute",
//                 top: 5,
//                 right: 5,
//                 backgroundColor: "rgba(255, 255, 255, 0.7)",
//                 borderRadius: "50%",
//                 height: 18,
//                 width: 18,
//                 cursor: "pointer",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 zIndex: 2,
//               }}
//             >
//               <EyeOutlined />
//             </div>
//             <div
//               style={{
//                 position: "absolute",
//                 bottom: 5,
//                 right: 5,
//                 height: 18,
//                 width: "max-content",
//                 zIndex: 2,
//                 fontWeight: "bold",
//               }}
//             >
//               {image.text || ""}
//             </div>
//           </div>
//         ))}

//         {state.lightBoxOpen && (
//           <Lightbox
//             images={state.lightBoxPhotos}
//             isOpen={state.lightBoxOpen}
//             currentImage={state.currentIndex}
//             onClickPrev={onClickPrevious}
//             onClickNext={onClickNext}
//             onClose={(e) => setState({ ...state, lightBoxOpen: false })}
//           />
//         )}
//       </div>
//     </div>
//   );
// };
export const ADUMessageImageSlctn = ({ aduImages, selectedImage }) => {
  const lightboxphotos = aduImages.map((values) => {
    return {
      src: values.url,
      type: null,
    };
  });

  const [state, setState] = useState({
    lightBoxOpen: false,
    currentIndex: 0,
    count: lightboxphotos.length,
    photos: lightboxphotos,
    lightBoxPhotos: lightboxphotos,
    isLoading: false,
    hasMore: true,
  });

  const onClickPhoto = (index) => {
    setState((prev) => ({
      ...prev,
      lightBoxOpen: true,
      currentIndex: index,
    }));
  };

  const onClickPrevious = () => {
    setState((prev) => ({
      ...prev,
      currentIndex: prev.currentIndex - 1,
    }));
  };

  const onClickNext = () => {
    setState((prev) => ({
      ...prev,
      currentIndex: prev.currentIndex + 1,
    }));
  };

  return (
    <div className="adu-fadein-animation" style={{ color: "black" }}>
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          width: "100%",
          maxWidth: "350px",
        }}
      >
        Selected image
      </div>

      <div
        style={{
          display: "grid",
          gap: 5,
          gridTemplateColumns: "1fr auto", // Two columns: images and legend
          width: "100%",
          maxWidth: "450px",
          marginTop: 10,
        }}
      >
        {/* Image Grid */}
        <div
          style={{
            display: "grid",
            gap: 5,
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "repeat(2, auto)",
            maxHeight: "350px",
          }}
        >
          {(aduImages || [])?.map((image, index) => (
            <div
              key={image.id}
              style={{
                position: "relative",
                border:
                  image?.id === selectedImage?.id
                    ? "2px solid rgb(245, 167, 36)"
                    : "2px solid lightgray",
                borderRadius: 8,
                padding: 2,
                opacity: image?.id === selectedImage?.id ? 0.7 : 1,
              }}
            >
              <img
                onClick={(e) => {
                  e.stopPropagation();
                }}
                src={image.url}
                alt={`ADU ${image.id}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 8,
                }}
              />
              <div
                onClick={() => onClickPhoto(index)}
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "50%",
                  height: 18,
                  width: 18,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 2,
                }}
              >
                <EyeOutlined />
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 5,
                  right: 5,
                  height: 18,
                  width: "max-content",
                  zIndex: 2,
                  fontWeight: "bold",
                }}
              >
                {image.text || ""}
              </div>
            </div>
          ))}
        </div>

        {/* Legend Section */}
        <LengendComp />
        {/* Lightbox Modal */}
        {state.lightBoxOpen && (
          <Lightbox
            images={state.lightBoxPhotos}
            isOpen={state.lightBoxOpen}
            currentImage={state.currentIndex}
            onClickPrev={onClickPrevious}
            onClickNext={onClickNext}
            onClose={(e) => setState({ ...state, lightBoxOpen: false })}
          />
        )}
      </div>
    </div>
  );
};
export const CalendlyPopup = ({ message, index, length }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTextClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={index === length - 1 ? "adu-fadein-animation" : ""}
      style={{
        marginTop: "10px",
      }}
    >
      <AntdButton
        key={index}
        type="primary"
        style={{
          borderRadius: "12px",
          color: "white",
          padding: "2px 12px",
          height: "unset",
        }}
        onClick={handleTextClick}
      >
        Schedule a Meeting
      </AntdButton>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 4,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              position: "relative",
            }}
          >
            <button
              onClick={handleClose}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
            <InlineWidget
              url={`${message?.calendlyLink}`}
              styles={{ height: "100%" }}
              pageSettings={{
                backgroundColor: "ffffff",
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: "00a2ff",
                textColor: "4d5055",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const ADUMessageCardWrapper = ({ children, sender, message }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        gap: 10,
      }}
    >
      {sender !== "user" && !message?.isBedBath && !message?.isAttachDetach && (
        <img src={aduCahtBotIcon} alt="adu" style={{ width: 40, height: 23 }} />
      )}

      {children}
    </div>
  );
};

export const LengendComp = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        alignItems: "flex-start",
        width: "100%",
        maxWidth: "max-content",
        paddingTop: 10,
      }}
    >
      <Tooltip
        title={
          <div
            style={{
              height: "4rem",
              width: "4rem",
              borderRadius: 4,
              backgroundColor: "#F38383",
            }}
          ></div>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            cursor: "pointer",
          }}
        >
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: "#F38383",
              borderRadius: 4,
            }}
          />
          <span>Living Room</span>
        </div>
      </Tooltip>
      <Tooltip
        title={
          <div
            style={{
              height: "4rem",
              width: "4rem",
              borderRadius: 4,
              backgroundColor: "#D2D2D2",
            }}
          ></div>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            cursor: "pointer",
          }}
        >
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: "#D2D2D2",
              borderRadius: 4,
            }}
          />
          <span>Bathroom</span>
        </div>
      </Tooltip>
      <Tooltip
        title={
          <div
            style={{
              height: "4rem",
              width: "4rem",
              borderRadius: 4,
              backgroundColor: "#FFE09E",
            }}
          ></div>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            cursor: "pointer",
          }}
        >
          <div
            style={{
              width: 15,
              height: 15,
              backgroundColor: "#FFE09E",
              borderRadius: 4,
            }}
          />
          <span>Bedroom</span>
        </div>
      </Tooltip>
    </div>
  );
};

export const ADUIsBedBathComp = ({
  value,
  bedroomOptions,
  bathroomOptions,
}) => {
  const [beds, baths] = value.split("|");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        padding: "2rem",
        maxWidth: "max-content",
        background: "white",
        borderRadius: "20px",
        border: "1px solid #DBDBDB",
        boxShadow: "0px 0px 25px 0px #C1C1C140",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: 10,
            }}
          >
            <div style={{ fontWeight: "bold" }}>Bedrooms</div>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(bedroomOptions || []).map((option, index) => (
              <div
                key={option}
                style={{
                  padding: "10px 20px",
                  border: ` ${
                    beds === option ? "1.5px solid #fd8c3a" : "1px solid #ccc"
                  }`,
                  backgroundColor: "#fff",
                  color: "#000",
                  fontWeight: "normal",
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>

        <div>
          <label
            style={{ fontWeight: "bold", marginBottom: 10, display: "block" }}
          >
            Bathrooms
          </label>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(bathroomOptions || []).map((option, index) => (
              <div
                key={option}
                style={{
                  padding: "10px 20px",
                  border: ` ${
                    baths === option ? "1.5px solid #fd8c3a" : "1px solid #ccc"
                  }`,
                  backgroundColor: "#fff",
                  color: "#000",
                  fontWeight: "normal",
                }}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ADUIsAttachDetach = ({ content, btnContent }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        marginTop: "10px",
        width: "100%",
      }}
    >
      {btnContent?.map((value, index) => {
        const isDetached = value.toLowerCase().includes("detached");
        const isAttached = value.toLowerCase().includes("attached");
        return (
          (isAttached || isDetached) && (
            <div
              key={index}
              style={{
                // backgroundColor:
                //   message?.sender === "user" ? "#E3E3E3" : "#F5A724",
                borderRadius: 20,
                // color: "white",
                padding: "12px 0 0 0",
                height: "unset",
                display: "flex",
                flexDirection: "column",
                gap: 5,
                width: "100%",
                maxWidth: 452,
                maxHeight: 452,
                overflow: "hidden",
                backgroundColor: "white",
                border:
                  content === value
                    ? "1.5px solid #fd8c3a"
                    : "1.5px solid #dbdbdb",
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  fontWeight: 600,
                  padding: "5px 20px",
                }}
              >
                {value}
              </div>
              <div
                style={{
                  fontSize: 14,
                  padding: "5px 20px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  textAlign: "left",
                }}
              >
                {isDetached
                  ? "A separate dwelling unit not attached to the main house, providing privacy and independence."
                  : "A unit that shares a wall with the main house, offering easy access and shared utilities."}
              </div>
              <img
                src={isAttached ? attachedAduImage : dettachedAduImage}
                alt="adu"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          )
        );
      })}
    </div>
  );
};

export const ADUShowCalendly = () => {
  const calendlycardStyles = {
    card: {
      backgroundColor: "#ffffff",
      borderRadius: "12px",
      padding: "20px",
      boxShadow: "0px 0px 25px 0px #C1C1C140",
      maxWidth: "400px",
      margin: "0 auto",
      border: "1px solid #DBDBDB",
    },
    badge: {
      display: "inline-block",
      backgroundColor: "#FFF0E7",
      color: "#9F4300",
      fontSize: "14px",
      fontWeight: "600",
      padding: "4px 8px",
      borderRadius: "12px",
      marginBottom: "16px",
    },
    details: {
      textAlign: "left",
    },
    lotSize: {
      fontSize: "16px",
      fontWeight: "500",
      color: "#333333",
      marginBottom: "16px",
    },
    costContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "16px",
    },
    costItem: {
      textAlign: "center",
    },
    costTitle: {
      fontSize: "14px",
      color: "#666666",
      marginBottom: "4px",
    },
    costValue: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#333333",
      marginBottom: "4px",
    },
    costSubtitle: {
      fontSize: "12px",
      color: "#999999",
    },
    disclaimer: {
      fontSize: "12px",
      color: "#666666",
      textAlign: "left",
      lineHeight: "1.4",
    },
  };
  const lotSize = "9424 sqft";
  const estimatedArea = "1200 sqft";
  const estimatedCost = "$378,000";
  const estimatedCostFormatted = `$315

`;
  return (
    <div style={calendlycardStyles.card}>
      <div style={calendlycardStyles.badge}>Cost Estimation</div>
      <div style={calendlycardStyles.details}>
        <p style={calendlycardStyles.lotSize}>
          Lot size: <strong>{lotSize}</strong>, Estimated ADU area:{" "}
          <strong>{estimatedArea}</strong>
        </p>
        <div style={calendlycardStyles.costContainer}>
          <div style={calendlycardStyles.costItem}>
            <p style={calendlycardStyles.costTitle}>Estimated total cost</p>
            <p style={calendlycardStyles.costValue}>{estimatedCost}</p>
            <p style={calendlycardStyles.costSubtitle}>
              Based on current market rates
            </p>
          </div>
          <div style={calendlycardStyles.costItem}>
            <p style={calendlycardStyles.costTitle}>Cost per square foot</p>
            <p style={calendlycardStyles.costValue}>{estimatedCostFormatted}</p>
            <p style={calendlycardStyles.costSubtitle}>
              Average construction cost
            </p>
          </div>
        </div>
        <p style={calendlycardStyles.disclaimer}>
          This estimation includes standard construction costs and basic
          finishes. Actual costs may vary based on design choices and site
          conditions.
        </p>
      </div>
    </div>
  );
};
