import { clientPost } from "../utils/apiclient";

export const apiLink = "https://test.spyguard.pro";
// export const apiLink = "http://192.168.18.62:8888";

// export const apiLink = "http://localhost:8888";
export const defaultMessage = {
  content: "",
  sender: "system",
  showAddress: false,
  showBedBathSelection: false,
  isBedBath: false,
  isAttachDetach: false,
  isImage: false,
  showImage: false,
  showCropImage: false,
  showButtons: false,
  btnContent: null,
  showCalendly: false,
  calendlyLink: "",
  showImgSlctnMsg: false,
  selectedImage: null,
  aduImages: [],
  timestamp: new Date(),
};

//For server update in progress
export const setErrorMessage = (setMessages, message) => {
  setMessages((prev) => {
    const content =
      prev.length === 0
        ? message || "Development in progress ⚙️, contact team for support"
        : message || "Something went wrong, please try later";

    const tempMessages = [
      ...prev,
      {
        ...defaultMessage,
        content,
      },
    ];
    return tempMessages;
  });
};

export const boldNumbersAndSymbols = (inputString) => {
  if (inputString) {
    // Regular expression to match numbers and symbols
    const regex = /[\d\W_]/g;

    // Replace the matched numbers and symbols with bold HTML tags
    const result = inputString.replace(regex, (match) => `<b>${match}</b>`);

    return result;
  } else {
    return inputString;
  }
};

export const handleConversation = async ({
  setTyping,
  value,
  sessionId,
  setMessages,
  setAduImages,
  setImageForCrop,
  bedrooms,
  bathrooms,
}) => {
  setTyping(true);
  if (value) {
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      const newMessage = lastMessage?.showCropImage
        ? {
            ...defaultMessage,
            content: "Selected area sent successfully.",
            showCropImage: false,
            sender: "system",
          }
        : lastMessage?.showAddress
        ? {
            ...defaultMessage,
            content: value,
            showAddress: false,
            sender: "user",
          }
        : {
            ...defaultMessage,
            content: value,
            sender: "user",
          };

      if (lastMessage?.showImage || lastMessage?.isImage) {
        return [...prevMessages];
      } else if (lastMessage?.showBedBathSelection) {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = {
          ...defaultMessage,
          content: `Bedrooms: ${bedrooms} | Bathrooms: ${bathrooms}`,
          showBedBathSelection: false,
          sender: "user",
        };

        updatedMessages.push({
          ...defaultMessage,
          showCropImage: true,
        });

        return updatedMessages;
      } else {
        return [...prevMessages, newMessage];
      }
    });

    return await clientPost(
      `${apiLink}/converse`,
      {
        sessionId: sessionId,
        message: value,
      },
      true
    )
      .then(async (resp) => {
        if (resp.status === 200) {
          const converseresp = await resp.json();

          // if (converseresp?.type === "bedbath") {
          //   setMessages((prev) => {
          //     const tempMessages = [
          //       ...prev,
          //       {
          //         ...defaultMessage,
          //         showBedBathSelection: true,
          //       },
          //     ];
          //     return tempMessages;
          //   });
          // } else
          if (converseresp?.type === "floorplan") {
            //set bed bath message
            setMessages((prev) => {
              const tempMessages = [
                ...prev,
                {
                  ...defaultMessage,
                  showBedBathSelection: true,
                },
              ];
              return tempMessages;
            });
            // then get image for cropping
            await getMapImage({
              sessionId,
              setImageForCrop,
            });
            // const floorresp = await generateFloorPlan({
            //   sessionId,
            //   bedrooms,
            //   bathrooms,
            //   setAduImages,
            // });

            // if (floorresp) {
            //   setMessages((prev) => {
            //     const tempMessages = [
            //       ...prev,
            //       {
            //         ...defaultMessage,
            //         showImage: true,
            //       },
            //     ];
            //     return tempMessages;
            //   });
            // }
            setTyping(false);
          }
          //  else if (converseresp?.type === "region") {
          //   await getMapImage({
          //     sessionId,
          //     setImageForCrop,
          //   });
          //   setTyping(false);
          // }
          else if (converseresp?.type === "calendar") {
            setMessages((prev) => {
              const tempMessages = [
                ...prev,
                {
                  ...defaultMessage,
                  content:
                    boldNumbersAndSymbols(converseresp.fulfillment_text) || "",
                  // showCalendly: true,
                  showCalendly:
                    converseresp?.type === "calendar" ? true : false,
                  calendlyLink:
                    converseresp?.type === "calendar"
                      ? converseresp?.content
                      : "", //Added by zeeshan, redundancy
                },
              ];
              return tempMessages;
            });
            setTyping(false);
          } else {
            setMessages((prev) => {
              const tempMessages = [
                ...prev,
                {
                  ...defaultMessage,
                  content:
                    boldNumbersAndSymbols(converseresp.fulfillment_text) || "",
                  sender: "system",
                  showButtons: converseresp.type === "buttons" ? true : false,
                  btnContent:
                    converseresp.type === "buttons" &&
                    converseresp.content &&
                    Array.isArray(converseresp.content)
                      ? converseresp.content
                      : null,
                },
              ];

              return tempMessages;
            });
            setTyping(false);
          }
          return;
        }
      })
      .catch((error) => {
        console.error("Error", error);
        setErrorMessage(setMessages);
        setTyping(false);
        return;
      });
  }
};

export const generateFloorPlan = async ({
  sessionId,
  bedrooms,
  bathrooms,
  setAduImages,
}) => {
  return await clientPost(
    `${apiLink}/generate_floorplans`,
    { sessionId: sessionId, bedrooms: bedrooms, bathrooms: bathrooms },
    true
  )
    .then(async (resp1) => {
      const floorplanresp = await resp1.json();

      if (
        floorplanresp &&
        typeof floorplanresp === "object" &&
        !Array.isArray(floorplanresp)
      ) {
        const resultArray = Object.keys(floorplanresp).map((key) => ({
          id: key,
          url: floorplanresp[key].dataUri || "",
          text: floorplanresp[key].text || "",
        }));

        setAduImages(resultArray);
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.error("Error", error);
      setAduImages([]);
      return false;
    });
};

export const getMapImage = async ({ sessionId, setImageForCrop }) => {
  return await clientPost(
    `${apiLink}/get_map_image`,
    { sessionId: sessionId, address: localStorage.getItem("aduChatAddress") },
    true
  )
    .then(async (resp) => {
      const response = await resp.json();

      if (response?.dataUri) {
        setImageForCrop(response.dataUri);
        return true;
      }
      return false;
    })
    .catch((error) => {
      console.error("Error", error);
      return false;
    });
};

export const getCropForImage = async ({ sessionId, width, height }) => {
  return await clientPost(
    `${apiLink}/get_bounding_box`,
    { sessionId: sessionId, displayedWidth: width, displayedHeight: height },
    true
  )
    .then(async (resp) => {
      const response = await resp.json();
      return response;
    })
    .catch((error) => {
      console.error("Error fetching bounding box:", error);
      return null;
    });
};

export const sendCroppedImage = async ({
  sessionId,
  setTyping,
  setMessages,
  completedCrop,
  imageForCrop,
  area,
  version,
}) => {
  setTyping(true);
  return await clientPost(
    `${apiLink}/use_floorplans`,
    {
      session_id: sessionId,
      version: version,
      x: completedCrop?.x,
      y: completedCrop?.y,
      scale_width: completedCrop?.width,
      displayed_width: completedCrop?.displayedWidth,
      displayed_height: completedCrop?.displayedHeight,
      map_image: imageForCrop,
      area: area,
    },
    true
  )
    .then(async (resp) => {
      const response = await resp.json();

      if (response?.resultImage) {
        setMessages((prev) => {
          const tempMessages = [
            ...prev,
            {
              ...defaultMessage,
              isImage: true,
              content: response?.resultImage,
            },
          ];
          return tempMessages;
        });

        clientPost(
          `${apiLink}/converse`,
          {
            sessionId: sessionId,
            message: "1",
          },
          true
        )
          .then(async (resp) => {
            if (resp.status === 200) {
              const response = await resp.json();

              setMessages((prev) => {
                const tempMessages = [
                  ...prev,
                  {
                    ...defaultMessage,
                    content:
                      boldNumbersAndSymbols(response.fulfillment_text) || "",
                    showCalendly: response?.type === "calendar" ? true : false,
                    calendlyLink:
                      response?.type === "calendar" ? response?.content : "",
                  },
                ];
                return tempMessages;
              });
              return;
            }
          })
          .catch(async (err) => {
            console.error("Error sending conversation:", err);
            setErrorMessage(setMessages);
            return;
          });
      }
      setTyping(false);
      return;
    })
    .catch((error) => {
      console.error("Error sending cropped image:", error);
      setErrorMessage(setMessages);
      setTyping(false);
      return;
    });
};
