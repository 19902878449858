import cookie from "js-cookie";
import { api_link } from "../constants/links";
// import { getLanguage } from "./languageutil";
// const FormData = require("form-data");

// Should use this method from now on
export const clientPostWithOptions = (url, options) => {
  return clientPost(
    url,
    options.data,
    options.isFullUrl,
    options.signalWrapper
  );
};

// Deprecated
export const clientPost = async (
  url,
  data = {},
  isFullUrl = false,
  signalWrapper = undefined
) => {
  // const language = getLanguage();
  let fullUrl = `${api_link}/${url}`;
  if (isFullUrl) {
    fullUrl = url;
  }

  const token = cookie.get("token");
  const params = {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      // "Accept-Language": language,
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  };
  if (data instanceof FormData) {
    const { "Content-Type": contentType, ...newHeaders } = params.headers;
    params.headers = newHeaders;
    params.body = data;
  }
  if (signalWrapper && signalWrapper.signal) {
    params.signal = signalWrapper.signal;
  }

  return await fetch(fullUrl, params);
};

// Should use this method from now on
export const clientGetWithOptions = (url, options) => {
  return clientGet(url, options.isFullUrl, options.signalWrapper);
};

// Deprecated
export const clientGet = async (
  url,
  isFullUrl = false,
  signalWrapper = undefined
) => {
  // const language = getLanguage();
  let fullUrl = `${api_link}/${url}`;
  if (isFullUrl) {
    fullUrl = url;
  }

  const token = cookie.get("token");
  const params = {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      // "Accept-Language": language,
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
    redirect: "follow", // manual, *follow, error
    referrer: "no-referrer", // no-referrer, *client
  };
  if (signalWrapper && signalWrapper.signal) {
    params.signal = signalWrapper.signal;
  }

  return await fetch(fullUrl, params);
};

export async function getApiUrl() {
  try {
    const response = await fetch("http://142.93.214.104:8432/get-url"); // Replace with your URL
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.apiUrl; // Adjust according to your response structure
  } catch (error) {
    console.error("Error fetching API URL:", error);
    return null;
  }
}
