import "./App.css";
import ADUChatBot from "./components/aduchatbot";
import "antd/dist/antd.css";

function App() {
  return (
    <div className="App">
      <div className="adu-chat-header">Chat with Homie</div>
      <ADUChatBot />
    </div>
  );
}

export default App;
